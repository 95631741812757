<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <c-card title="기본정보" class="cardClassDetailForm">
              <template slot="card-button">
                <q-btn-group outline >
                  <c-btn
                    v-if="editable"
                    :url="saveUrl"
                    :isSubmit="isSave"
                    :param="data"
                    :mappingType="mappingType"
                    label="저장"
                    icon="save"
                    @beforeAction="saveInfo"
                    @btnCallback="saveCallback" />
                  <c-btn
                    v-if="editable && updateMode"
                    label="삭제"
                    icon="remove"
                    @btnClicked="deleteData" />
                </q-btn-group>
              </template>
              <template slot="card-detail">
                <div class="col-6">
                  <c-text
                    :required="true"
                    :editable="editable"
                    label="측정명"
                    name="envAirSelfMeasureMstName"
                    v-model="data.envAirSelfMeasureMstName">
                  </c-text>
                </div>
                <div class="col-2">
                  <c-datepicker
                    :required="true"
                    :editable="editable && !updateMode"
                    label="측정년도"
                    type="year"
                    default="today"
                    name="measureYear"
                    v-model="data.measureYear"
                  />
                </div>
                <div class="col-2">
                  <c-select
                    :required="true"
                    type="edit"
                    :editable="editable && !updateMode"
                    codeGroupCd="AIR_INSPECT_TYPE_CD"
                    itemText="codeName"
                    itemValue="code"
                    label="측정구분"
                    name="airInspectTypeCd"
                    v-model="data.airInspectTypeCd">
                  </c-select>
                </div>
                <div class="col-2">
                  <c-plant 
                    :editable="editable && !updateMode" 
                    :required="true" 
                    type="edit" 
                    name="plantCd" 
                    v-model="data.plantCd" />
                </div>
              </template>
            </c-card>
          
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-show="popupParam.envAirSelfMeasureMstId">
        <c-table
          ref="grid"
          title="배출구별 검사결과"
          :columns="grid.columns"
          :data="grid.data"
          :editable="editable"
          :columnSetting="false"
          :filtering="false"
          :pagePerRow="{pageNumber: 20}"
          :gridHeight="grid.height"
        >
          <!-- 버튼 영역 -->
          <template slot="suffixTitle">
            &nbsp;&nbsp;&nbsp;&nbsp;<font style="font-size:0.85em;font-weight:300;"><i class="material-icons bg-red-6 text-red-6">check</i> 법적기준 초과&nbsp;&nbsp;&nbsp;<i class="material-icons bg-orange-4 text-orange-4">check</i> 내부관리기준 초과&nbsp;&nbsp;&nbsp;<i class="material-icons bg-grey-9 text-grey-9">check</i> 미측정 (-1 입력시 미측정처리)&nbsp;&nbsp;&nbsp;</font>
          </template>
          <template slot="table-button">
            <q-btn-group outline >
              <c-checkbox
                :isFlag="true"
                falseLabel="미측정 배출구 제외"
                trueLabel="미측정 배출구 제외"
                name="existData"
                addClasses="tableCheckbox-top"
                v-model="existData"
                @input="getList"
              />
              <c-btn
                v-if="editable && updateMode"
                label="엑셀업로드"
                icon="upload"
                @btnClicked="excelUploadData" />
              <!-- <q-btn
                icon="help"
                color="deep-purple-6"
                text-color="white"
                class="custom-btn"
                align="center"
                round>
                <q-tooltip anchor="bottom left" self="top left">
                  <div class="tooltipCustomTop">
                    (주)
                  </div>
                  <div class="tooltipCustom">
                  법적기준 초과 : <i class="material-icons bg-red-6 text-red-6">check</i><br>
                  내부관리기준 초과 : <i class="material-icons bg-orange-4 text-orange-4">check</i>
                  </div>
                </q-tooltip>
              </q-btn> -->
            </q-btn-group>
          </template>
        </c-table>
      <c-dialog :param="popupOptions"></c-dialog>
      </div>
    </q-form>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'self-air-measure-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          envAirSelfMeasureMstId: '',
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      existData: 'Y',
      saveUrl: transactionConfig.env.air.self.measure.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      headerUrl: '',
      listUrl: '',
      saveOutletUrl: '',
      editable: true,
      isSave: false,
      updateMode: false,
      envAirMstInspectItemIds: [],
      data: {
        plantCd: null,  // 사업장코드
        envAirSelfMeasureMstId: '',  // 대기 자가측정 일련번호
        measureYear: '',  // 대기 자가측정 년도
        airInspectTypeCd: null,  // 대기 검사항목 구분
        envAirSelfMeasureMstName: '',  // 대기 자가측정명
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'measureDt',
            field: 'measureDt',
            label: '측정일',
            align: 'center',
            type: 'date',
            style: 'width: 120px',
            sortable: false,
            setHeader: true,
            fix: true,
          },
          {
            name: 'envAirMstOutletName',
            field: 'envAirMstOutletName',
            label: '배출구',
            align: 'center',
            style: 'width: 80px',
            sortable: false,
            fix: true,
          },
          {
            name: 'envAirMstOutletDischargeName',
            field: 'envAirMstOutletDischargeName',
            label: '배출시설',
            align: 'center',
            style: 'width: 120px',
            sortable: false,
            fix: true,
          },
          {
            name: 'location',
            field: 'location',
            label: '위치',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
            fix: true,
          },
          {
            name: 'handlingCapacity',
            field: 'handlingCapacity',
            label: '처리용량<br>(㎥/분)',
            align: 'center',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'permitCapacity',
            field: 'permitCapacity',
            label: '설계(허가증)<br>용량(㎥/분)',
            align: 'center',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'capacityPercent',
            field: 'capacityPercent',
            label: '허가증기준<br>유량(%)',
            align: 'center',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'dynamicPressure',
            field: 'dynamicPressure',
            label: '동압',
            align: 'center',
            type: 'number',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'staticPressure',
            field: 'staticPressure',
            label: '정압',
            align: 'center',
            type: 'number',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'gasQuantity',
            field: 'gasQuantity',
            label: '가스 유량<br>(S㎥/분)',
            align: 'center',
            type: 'number',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'quantity',
            field: 'quantity',
            label: '유량(%)',
            align: 'center',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'gasTemp',
            field: 'gasTemp',
            label: '가스온도(℃)',
            align: 'center',
            type: 'number',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'waterQuantity',
            field: 'waterQuantity',
            label: '수분량(%)',
            align: 'center',
            type: 'number',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'velocity',
            field: 'velocity',
            label: '유속(m/sec)',
            align: 'center',
            type: 'number',
            style: 'width: 90px',
            sortable: false,
          },
        ],
        data: [],
        height: '',
      },
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.env.air.self.measure.get.url;
      this.headerUrl = selectConfig.env.air.self.outlet.header.url;
      this.listUrl = selectConfig.env.air.self.outlet.list.url;
      this.insertUrl = transactionConfig.env.air.self.measure.insert.url;
      this.updateUrl = transactionConfig.env.air.self.measure.update.url;
      this.deleteUrl = transactionConfig.env.air.self.measure.delete.url;
      this.saveOutletUrl = transactionConfig.env.air.self.outlet.save.url;
      
      this.grid.height = (window.innerHeight - 220) + 'px';
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.envAirSelfMeasureMstId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {envAirSelfMeasureMstId: this.popupParam.envAirSelfMeasureMstId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.updateMode = true;
          this.getHeader();
        },);
      } else {
        this.data.measureYear = this.$comm.getThisYear();
        this.updateMode = false;
      }
    },
    getHeader() { 
      this.$http.url = this.headerUrl;
      this.$http.param = {airInspectTypeCd: this.data.airInspectTypeCd, plantCd: this.data.plantCd}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        let count = 7;
        this.envAirMstInspectItemIds = [];
        this.$_.forEach(_result.data, item => {
          this.envAirMstInspectItemIds.push(item.envAirMstInspectItemId);
          this.grid.columns.splice(count,0,
            {
              name: item.envAirMstInspectItemId,
              field: item.envAirMstInspectItemId,
              label: item.envAirMstInspectItemName + (item.airInspectUnitName ? ' (' + item.airInspectUnitName + ')' : '') + '<br>',
              align: 'center',
              type: 'number',
              style: 'width: 90px',
              sortable: false,
              disableTarget: item.envAirMstInspectItemId + '002',
              disableCon: 'Y',
              disableData: '-',
              colorClass: ['R,bg-red-6 text-white','I,bg-orange-4 text-white'],
              colorTarget: item.envAirMstInspectItemId + '001',
              colorTarget2: item.envAirMstInspectItemId + 'MeasureFlag',
              colorTarget3: item.envAirMstInspectItemId + 'EditFlag',
              // helpcomment: '법적기준: ' + item.legalStandard + '<br>내부기준: ' + item.innerStandard,
            }
          )
          count++;
        });
        this.getList();
      },);
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.param = {
        envAirSelfMeasureMstId: this.data.envAirSelfMeasureMstId, 
        plantCd: this.data.plantCd,
        envAirMstInspectItemIds: this.envAirMstInspectItemIds, 
        airInspectTypeCd: this.data.airInspectTypeCd,
        existData: this.existData
      }
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    saveInfo() {
      if (this.popupParam.envAirSelfMeasureMstId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info', // success / info / warning / error
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.popupParam.envAirSelfMeasureMstId = result.data.envAirSelfMeasureMstId;
      if (this.mappingType == 'PUT') {
        this.saveRow();
      } else {
        this.getDetail();
        window.getApp.$emit('APP_REQUEST_SUCCESS');
      }
    },
    saveRow() {
      let saveData = this.grid.data.filter( x => {
        return x.editFlag == 'C' || x.editFlag == 'U'
      });
      this.$_.forEach(saveData, item => {
        item.regUserId = this.$store.getters.user.userId;
        item.chgUserId = this.$store.getters.user.userId;
      });
      if(saveData.length > 0) {
        if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
          this.$_.forEach(this.grid.data, _item => {
            let AirSelfMeasureInspectItemLists = [];
            var keys = Object.keys(_item);
            for (var i=0; i<keys.length; i++) {
              var key = keys[i];
              if (key.length == 10 && key.indexOf('AMII') > -1 && _item[key+'002'] == 'Y') { // && parseFloat(_item[key]) > 0
                AirSelfMeasureInspectItemLists.push({
                  envAirSelfMeasureMstId: _item.envAirSelfMeasureMstId,
                  envAirMstOutletId: _item.envAirMstOutletId,
                  envAirMstInspectItemId: key,
                  measure: parseFloat(_item[key]),
                  regUserId: this.$store.getters.user.userId,
                  chgUserId: this.$store.getters.user.userId,
                  editFlag: _item.editFlag,
                });
              }
            }
            _item.airSelfMeasureInspectItems = AirSelfMeasureInspectItemLists;
          });
          this.$http.url = transactionConfig.env.air.self.outlet.save.url;
          this.$http.type = 'POST';
          this.$http.param = this.grid.data;
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.getList();
          });
        }
      }
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.envAirSelfMeasureMstId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    excelUploadData() {
      this.popupOptions.title = '대기-자가측정결과 업로드';
      this.popupOptions.param = {
        envAirSelfMeasureMstId: this.popupParam.envAirSelfMeasureMstId,
      }
      this.popupOptions.target = () => import(`${'./selfAirMeasureExcelUpload.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelUploadPopup;
    },
    closeExcelUploadPopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        this.$_.forEach(s_data, item => {
          item.plantCd = this.data.plantCd;
          item.envAirSelfMeasureMstId = this.popupParam.envAirSelfMeasureMstId;
          item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
          item.chgUserId = this.$store.getters.user.userId;  // 수정자 ID
          
          let AirSelfMeasureInspectItemLists = [];
          var keys = Object.keys(item);
          for (var i=0; i<keys.length; i++) {
            var key = keys[i];
            if (key.length == 10 && key.indexOf('AMII') > -1 && parseFloat(item[key]) !== undefined) {
              AirSelfMeasureInspectItemLists.push({
                envAirSelfMeasureMstId: item.envAirSelfMeasureMstId,
                envAirMstOutletName: item.envAirMstOutletName,
                envAirMstInspectItemId: key,
                measure: parseFloat(item[key])
              });
            }
          }
          item.airSelfMeasureInspectItems = AirSelfMeasureInspectItemLists;
        })

        this.$http.url = this.saveOutletUrl + '/excel';
        this.$http.type = 'POST';
        this.$http.param = s_data;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        });
      }
    },
    // getData() {
    //   this.grid.data = [
    //     {
    //       col0: 'Y',
    //       col1: 'G-10-02',
    //       col2: 'G1A (저장~혼합)',
    //       col3: '10층',
    //       col4: '300',
    //       col5: '181.61',
    //       col6: '61%',
    //       col72: 'Y', // 배출구별 검사항목 여부
    //       col7: '0.146',
    //       col71: 'N', // N: 적정, R: 법적기준초과, I:내부기준초과
    //       col82: 'Y', // 배출구별 검사항목 여부
    //       col8: '2.12',
    //       col81: 'R', // N: 적정, R: 법적기준초과, I:내부기준초과
    //       col92: 'Y', // 배출구별 검사항목 여부
    //       col9: '불검출',
    //       col91: 'N', // N: 적정, R: 법적기준초과, I:내부기준초과
    //       col102: 'N', // 배출구별 검사항목 여부
    //       col10: '-',
    //       col101: 'N', // N: 적정, R: 법적기준초과, I:내부기준초과
    //       col11: '171.17',
    //       col12: '57%',
    //       col13: '27',
    //       col14: '2.7',
    //       col15: '10.09',
    //     },
    //     {
    //       col0: 'Y',
    //       col1: 'G-10-03',
    //       col2: 'G1C (저장~혼합)',
    //       col3: '10층',
    //       col4: '300',
    //       col5: '137.59',
    //       col6: '46%',
    //       col72: 'Y', // 배출구별 검사항목 여부
    //       col7: '0.059',
    //       col71: 'N', // N: 적정, R: 법적기준초과, I:내부기준초과
    //       col82: 'Y', // 배출구별 검사항목 여부
    //       col8: '0.68',
    //       col81: 'I', // N: 적정, R: 법적기준초과, I:내부기준초과
    //       col92: 'Y', // 배출구별 검사항목 여부
    //       col9: '불검출',
    //       col91: 'N', // N: 적정, R: 법적기준초과, I:내부기준초과
    //       col102: 'N', // 배출구별 검사항목 여부
    //       col10: '-',
    //       col101: 'N', // N: 적정, R: 법적기준초과, I:내부기준초과
    //       col11: '150.4',
    //       col12: '50%',
    //       col13: '19',
    //       col14: '2.7',
    //       col15: '8.38',
    //     },
    //     {
    //       col0: 'Y',
    //       col1: 'G-07-07',
    //       col2: 'G3A (소성)',
    //       col3: '8층 옥외',
    //       col4: '30',
    //       col5: '26.37',
    //       col6: '88%',
    //       col72: 'Y', // 배출구별 검사항목 여부
    //       col7: '0.19',
    //       col71: 'N', // N: 적정, R: 법적기준초과, I:내부기준초과
    //       col82: 'N', // 배출구별 검사항목 여부
    //       col8: '-',
    //       col81: 'N', // N: 적정, R: 법적기준초과, I:내부기준초과
    //       col92: 'Y', // 배출구별 검사항목 여부
    //       col9: '불검출',
    //       col91: 'N', // N: 적정, R: 법적기준초과, I:내부기준초과
    //       col102: 'Y', // 배출구별 검사항목 여부
    //       col10: '1.0',
    //       col101: 'N', // N: 적정, R: 법적기준초과, I:내부기준초과
    //       col11: '24.21',
    //       col12: '81%',
    //       col13: '56.0',
    //       col14: '9.8',
    //       col15: '10.21',
    //     },
    //   ]
    // },
  }
};
</script>